var exports = {};
/*! recommend.umd.js | 4.17.0 | © Algolia, inc. | https://github.com/algolia/algoliasearch-client-javascript */
!function (e, t) {
  exports = t();
}(exports, function () {
  "use strict";

  function e(e, t, r) {
    return t in e ? Object.defineProperty(e, t, {
      value: r,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[t] = r, e;
  }
  function t(e, t) {
    var r = Object.keys(e);
    if (Object.getOwnPropertySymbols) {
      var n = Object.getOwnPropertySymbols(e);
      t && (n = n.filter(function (t) {
        return Object.getOwnPropertyDescriptor(e, t).enumerable;
      })), r.push.apply(r, n);
    }
    return r;
  }
  function r(r) {
    for (var n = 1; n < arguments.length; n++) {
      var o = null != arguments[n] ? arguments[n] : {};
      n % 2 ? t(Object(o), !0).forEach(function (t) {
        e(r, t, o[t]);
      }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(r, Object.getOwnPropertyDescriptors(o)) : t(Object(o)).forEach(function (e) {
        Object.defineProperty(r, e, Object.getOwnPropertyDescriptor(o, e));
      });
    }
    return r;
  }
  function n(e, t) {
    return function (e) {
      if (Array.isArray(e)) return e;
    }(e) || function (e, t) {
      if (!(Symbol.iterator in Object(e) || "[object Arguments]" === Object.prototype.toString.call(e))) return;
      var r = [],
        n = !0,
        o = !1,
        a = void 0;
      try {
        for (var u, i = e[Symbol.iterator](); !(n = (u = i.next()).done) && (r.push(u.value), !t || r.length !== t); n = !0);
      } catch (e) {
        o = !0, a = e;
      } finally {
        try {
          n || null == i.return || i.return();
        } finally {
          if (o) throw a;
        }
      }
      return r;
    }(e, t) || function () {
      throw new TypeError("Invalid attempt to destructure non-iterable instance");
    }();
  }
  function o(e) {
    return function (e) {
      if (Array.isArray(e)) {
        for (var t = 0, r = new Array(e.length); t < e.length; t++) r[t] = e[t];
        return r;
      }
    }(e) || function (e) {
      if (Symbol.iterator in Object(e) || "[object Arguments]" === Object.prototype.toString.call(e)) return Array.from(e);
    }(e) || function () {
      throw new TypeError("Invalid attempt to spread non-iterable instance");
    }();
  }
  function a(e) {
    var t,
      r = "algoliasearch-client-js-".concat(e.key),
      o = function () {
        return void 0 === t && (t = e.localStorage || window.localStorage), t;
      },
      a = function () {
        return JSON.parse(o().getItem(r) || "{}");
      };
    return {
      get: function (e, t) {
        var r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {
          miss: function () {
            return Promise.resolve();
          }
        };
        return Promise.resolve().then(function () {
          var r = JSON.stringify(e),
            n = a()[r];
          return Promise.all([n || t(), void 0 !== n]);
        }).then(function (e) {
          var t = n(e, 2),
            o = t[0],
            a = t[1];
          return Promise.all([o, a || r.miss(o)]);
        }).then(function (e) {
          return n(e, 1)[0];
        });
      },
      set: function (e, t) {
        return Promise.resolve().then(function () {
          var n = a();
          return n[JSON.stringify(e)] = t, o().setItem(r, JSON.stringify(n)), t;
        });
      },
      delete: function (e) {
        return Promise.resolve().then(function () {
          var t = a();
          delete t[JSON.stringify(e)], o().setItem(r, JSON.stringify(t));
        });
      },
      clear: function () {
        return Promise.resolve().then(function () {
          o().removeItem(r);
        });
      }
    };
  }
  function u(e) {
    var t = o(e.caches),
      r = t.shift();
    return void 0 === r ? {
      get: function (e, t) {
        var r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {
            miss: function () {
              return Promise.resolve();
            }
          },
          o = t();
        return o.then(function (e) {
          return Promise.all([e, r.miss(e)]);
        }).then(function (e) {
          return n(e, 1)[0];
        });
      },
      set: function (e, t) {
        return Promise.resolve(t);
      },
      delete: function (e) {
        return Promise.resolve();
      },
      clear: function () {
        return Promise.resolve();
      }
    } : {
      get: function (e, n) {
        var o = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {
          miss: function () {
            return Promise.resolve();
          }
        };
        return r.get(e, n, o).catch(function () {
          return u({
            caches: t
          }).get(e, n, o);
        });
      },
      set: function (e, n) {
        return r.set(e, n).catch(function () {
          return u({
            caches: t
          }).set(e, n);
        });
      },
      delete: function (e) {
        return r.delete(e).catch(function () {
          return u({
            caches: t
          }).delete(e);
        });
      },
      clear: function () {
        return r.clear().catch(function () {
          return u({
            caches: t
          }).clear();
        });
      }
    };
  }
  function i() {
    var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {
        serializable: !0
      },
      t = {};
    return {
      get: function (r, n) {
        var o = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {
            miss: function () {
              return Promise.resolve();
            }
          },
          a = JSON.stringify(r);
        if (a in t) return Promise.resolve(e.serializable ? JSON.parse(t[a]) : t[a]);
        var u = n(),
          i = o && o.miss || function () {
            return Promise.resolve();
          };
        return u.then(function (e) {
          return i(e);
        }).then(function () {
          return u;
        });
      },
      set: function (r, n) {
        return t[JSON.stringify(r)] = e.serializable ? JSON.stringify(n) : n, Promise.resolve(n);
      },
      delete: function (e) {
        return delete t[JSON.stringify(e)], Promise.resolve();
      },
      clear: function () {
        return t = {}, Promise.resolve();
      }
    };
  }
  function s(e) {
    for (var t = e.length - 1; t > 0; t--) {
      var r = Math.floor(Math.random() * (t + 1)),
        n = e[t];
      e[t] = e[r], e[r] = n;
    }
    return e;
  }
  var c = {
      WithinQueryParameters: 0,
      WithinHeaders: 1
    },
    l = 1,
    f = 2,
    d = 3;
  function h(e, t) {
    var r = e || {},
      n = r.data || {};
    return Object.keys(r).forEach(function (e) {
      -1 === ["timeout", "headers", "queryParameters", "data", "cacheable"].indexOf(e) && (n[e] = r[e]);
    }), {
      data: Object.entries(n).length > 0 ? n : void 0,
      timeout: r.timeout || t,
      headers: r.headers || {},
      queryParameters: r.queryParameters || {},
      cacheable: r.cacheable
    };
  }
  var m = {
      Read: 1,
      Write: 2,
      Any: 3
    },
    p = 1,
    g = 2,
    v = 3;
  function y(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : p;
    return r(r({}, e), {}, {
      status: t,
      lastUpdate: Date.now()
    });
  }
  function b(e) {
    return "string" == typeof e ? {
      protocol: "https",
      url: e,
      accept: m.Any
    } : {
      protocol: e.protocol || "https",
      url: e.url,
      accept: e.accept || m.Any
    };
  }
  var P = "GET",
    O = "POST";
  function q(e, t) {
    return Promise.all(t.map(function (t) {
      return e.get(t, function () {
        return Promise.resolve(y(t));
      });
    })).then(function (e) {
      var r = e.filter(function (e) {
          return function (e) {
            return e.status === p || Date.now() - e.lastUpdate > 120000;
          }(e);
        }),
        n = e.filter(function (e) {
          return function (e) {
            return e.status === v && Date.now() - e.lastUpdate <= 120000;
          }(e);
        }),
        a = [].concat(o(r), o(n));
      return {
        getTimeout: function (e, t) {
          return (0 === n.length && 0 === e ? 1 : n.length + 3 + e) * t;
        },
        statelessHosts: a.length > 0 ? a.map(function (e) {
          return b(e);
        }) : t
      };
    });
  }
  function S(e, t, n, a) {
    var u = [],
      i = function (e, t) {
        if (e.method === P || void 0 === e.data && void 0 === t.data) return;
        var n = Array.isArray(e.data) ? e.data : r(r({}, e.data), t.data);
        return JSON.stringify(n);
      }(n, a),
      s = function (e, t) {
        var n = r(r({}, e.headers), t.headers),
          o = {};
        return Object.keys(n).forEach(function (e) {
          var t = n[e];
          o[e.toLowerCase()] = t;
        }), o;
      }(e, a),
      c = n.method,
      l = n.method !== P ? {} : r(r({}, n.data), a.data),
      f = r(r(r({
        "x-algolia-agent": e.userAgent.value
      }, e.queryParameters), l), a.queryParameters),
      d = 0,
      h = function t(r, o) {
        var l = r.pop();
        if (void 0 === l) throw {
          name: "RetryError",
          message: "Unreachable hosts - your application id may be incorrect. If the error persists, contact support@algolia.com.",
          transporterStackTrace: T(u)
        };
        var h = {
            data: i,
            headers: s,
            method: c,
            url: w(l, n.path, f),
            connectTimeout: o(d, e.timeouts.connect),
            responseTimeout: o(d, a.timeout)
          },
          m = function (e) {
            var t = {
              request: h,
              response: e,
              host: l,
              triesLeft: r.length
            };
            return u.push(t), t;
          },
          p = {
            onSuccess: function (e) {
              return function (e) {
                try {
                  return JSON.parse(e.content);
                } catch (t) {
                  throw function (e, t) {
                    return {
                      name: "DeserializationError",
                      message: e,
                      response: t
                    };
                  }(t.message, e);
                }
              }(e);
            },
            onRetry: function (n) {
              var a = m(n);
              return n.isTimedOut && d++, Promise.all([e.logger.info("Retryable failure", A(a)), e.hostsCache.set(l, y(l, n.isTimedOut ? v : g))]).then(function () {
                return t(r, o);
              });
            },
            onFail: function (e) {
              throw m(e), function (e, t) {
                var r = e.content,
                  n = e.status,
                  o = r;
                try {
                  o = JSON.parse(r).message;
                } catch (e) {}
                return function (e, t, r) {
                  return {
                    name: "ApiError",
                    message: e,
                    status: t,
                    transporterStackTrace: r
                  };
                }(o, n, t);
              }(e, T(u));
            }
          };
        return e.requester.send(h).then(function (e) {
          return function (e, t) {
            return function (e) {
              var t = e.status;
              return e.isTimedOut || function (e) {
                var t = e.isTimedOut,
                  r = e.status;
                return !t && 0 == ~~r;
              }(e) || 2 != ~~(t / 100) && 4 != ~~(t / 100);
            }(e) ? t.onRetry(e) : 2 == ~~(e.status / 100) ? t.onSuccess(e) : t.onFail(e);
          }(e, p);
        });
      };
    return q(e.hostsCache, t).then(function (e) {
      return h(o(e.statelessHosts).reverse(), e.getTimeout);
    });
  }
  function j(e) {
    var t = {
      value: "Algolia for JavaScript (".concat(e, ")"),
      add: function (e) {
        var r = "; ".concat(e.segment).concat(void 0 !== e.version ? " (".concat(e.version, ")") : "");
        return -1 === t.value.indexOf(r) && (t.value = "".concat(t.value).concat(r)), t;
      }
    };
    return t;
  }
  function w(e, t, r) {
    var n,
      o = (n = r, Object.keys(n).map(function (e) {
        return function (e) {
          for (var t = arguments.length, r = new Array(t > 1 ? t - 1 : 0), n = 1; n < t; n++) r[n - 1] = arguments[n];
          var o = 0;
          return e.replace(/%s/g, function () {
            return encodeURIComponent(r[o++]);
          });
        }("%s=%s", e, (t = n[e], "[object Object]" === Object.prototype.toString.call(t) || "[object Array]" === Object.prototype.toString.call(t) ? JSON.stringify(n[e]) : n[e]));
        var t;
      }).join("&")),
      a = "".concat(e.protocol, "://").concat(e.url, "/").concat("/" === t.charAt(0) ? t.substr(1) : t);
    return o.length && (a += "?".concat(o)), a;
  }
  function T(e) {
    return e.map(function (e) {
      return A(e);
    });
  }
  function A(e) {
    var t = e.request.headers["x-algolia-api-key"] ? {
      "x-algolia-api-key": "*****"
    } : {};
    return r(r({}, e), {}, {
      request: r(r({}, e.request), {}, {
        headers: r(r({}, e.request.headers), t)
      })
    });
  }
  var C = function (e) {
      var t = e.appId,
        o = function (e, t, r) {
          var n = {
            "x-algolia-api-key": r,
            "x-algolia-application-id": t
          };
          return {
            headers: function () {
              return e === c.WithinHeaders ? n : {};
            },
            queryParameters: function () {
              return e === c.WithinQueryParameters ? n : {};
            }
          };
        }(void 0 !== e.authMode ? e.authMode : c.WithinHeaders, t, e.apiKey),
        a = function (e) {
          var t = e.hostsCache,
            r = e.logger,
            o = e.requester,
            a = e.requestsCache,
            u = e.responsesCache,
            i = e.timeouts,
            s = e.userAgent,
            c = e.hosts,
            l = e.queryParameters,
            f = {
              hostsCache: t,
              logger: r,
              requester: o,
              requestsCache: a,
              responsesCache: u,
              timeouts: i,
              userAgent: s,
              headers: e.headers,
              queryParameters: l,
              hosts: c.map(function (e) {
                return b(e);
              }),
              read: function (e, t) {
                var r = h(t, f.timeouts.read),
                  o = function () {
                    return S(f, f.hosts.filter(function (e) {
                      return 0 != (e.accept & m.Read);
                    }), e, r);
                  };
                if (!0 !== (void 0 !== r.cacheable ? r.cacheable : e.cacheable)) return o();
                var a = {
                  request: e,
                  mappedRequestOptions: r,
                  transporter: {
                    queryParameters: f.queryParameters,
                    headers: f.headers
                  }
                };
                return f.responsesCache.get(a, function () {
                  return f.requestsCache.get(a, function () {
                    return f.requestsCache.set(a, o()).then(function (e) {
                      return Promise.all([f.requestsCache.delete(a), e]);
                    }, function (e) {
                      return Promise.all([f.requestsCache.delete(a), Promise.reject(e)]);
                    }).then(function (e) {
                      var t = n(e, 2);
                      t[0];
                      return t[1];
                    });
                  });
                }, {
                  miss: function (e) {
                    return f.responsesCache.set(a, e);
                  }
                });
              },
              write: function (e, t) {
                return S(f, f.hosts.filter(function (e) {
                  return 0 != (e.accept & m.Write);
                }), e, h(t, f.timeouts.write));
              }
            };
          return f;
        }(r(r({
          hosts: [{
            url: "".concat(t, "-dsn.algolia.net"),
            accept: m.Read
          }, {
            url: "".concat(t, ".algolia.net"),
            accept: m.Write
          }].concat(s([{
            url: "".concat(t, "-1.algolianet.com")
          }, {
            url: "".concat(t, "-2.algolianet.com")
          }, {
            url: "".concat(t, "-3.algolianet.com")
          }]))
        }, e), {}, {
          headers: r(r(r({}, o.headers()), {
            "content-type": "application/x-www-form-urlencoded"
          }), e.headers),
          queryParameters: r(r({}, o.queryParameters()), e.queryParameters)
        }));
      return function (e, t) {
        return t ? (Object.keys(t).forEach(function (r) {
          e[r] = t[r](e);
        }), e) : e;
      }({
        transporter: a,
        appId: t,
        addAlgoliaAgent: function (e, t) {
          a.userAgent.add({
            segment: e,
            version: t
          });
        },
        clearCache: function () {
          return Promise.all([a.requestsCache.clear(), a.responsesCache.clear()]).then(function () {});
        }
      }, e.methods);
    },
    N = function (e) {
      return function (t, n) {
        var o = t.map(function (e) {
          return r(r({}, e), {}, {
            threshold: e.threshold || 0
          });
        });
        return e.transporter.read({
          method: O,
          path: "1/indexes/*/recommendations",
          data: {
            requests: o
          },
          cacheable: !0
        }, n);
      };
    },
    k = function (e) {
      return function (t, n) {
        return N(e)(t.map(function (e) {
          return r(r({}, e), {}, {
            fallbackParameters: {},
            model: "bought-together"
          });
        }), n);
      };
    },
    x = function (e) {
      return function (t, n) {
        return N(e)(t.map(function (e) {
          return r(r({}, e), {}, {
            model: "related-products"
          });
        }), n);
      };
    },
    J = function (e) {
      return function (t, n) {
        var o = t.map(function (e) {
          return r(r({}, e), {}, {
            model: "trending-facets",
            threshold: e.threshold || 0
          });
        });
        return e.transporter.read({
          method: O,
          path: "1/indexes/*/recommendations",
          data: {
            requests: o
          },
          cacheable: !0
        }, n);
      };
    },
    E = function (e) {
      return function (t, n) {
        var o = t.map(function (e) {
          return r(r({}, e), {}, {
            model: "trending-items",
            threshold: e.threshold || 0
          });
        });
        return e.transporter.read({
          method: O,
          path: "1/indexes/*/recommendations",
          data: {
            requests: o
          },
          cacheable: !0
        }, n);
      };
    };
  function R(e, t, n) {
    var o,
      s = {
        appId: e,
        apiKey: t,
        timeouts: {
          connect: 1,
          read: 2,
          write: 30
        },
        requester: {
          send: function (e) {
            return new Promise(function (t) {
              var r = new XMLHttpRequest();
              r.open(e.method, e.url, !0), Object.keys(e.headers).forEach(function (t) {
                return r.setRequestHeader(t, e.headers[t]);
              });
              var n,
                o = function (e, n) {
                  return setTimeout(function () {
                    r.abort(), t({
                      status: 0,
                      content: n,
                      isTimedOut: !0
                    });
                  }, 1000 * e);
                },
                a = o(e.connectTimeout, "Connection timeout");
              r.onreadystatechange = function () {
                r.readyState > r.OPENED && void 0 === n && (clearTimeout(a), n = o(e.responseTimeout, "Socket timeout"));
              }, r.onerror = function () {
                0 === r.status && (clearTimeout(a), clearTimeout(n), t({
                  content: r.responseText || "Network request failed",
                  status: r.status,
                  isTimedOut: !1
                }));
              }, r.onload = function () {
                clearTimeout(a), clearTimeout(n), t({
                  content: r.responseText,
                  status: r.status,
                  isTimedOut: !1
                });
              }, r.send(e.data);
            });
          }
        },
        logger: (o = d, {
          debug: function (e, t) {
            return l >= o && console.debug(e, t), Promise.resolve();
          },
          info: function (e, t) {
            return f >= o && console.info(e, t), Promise.resolve();
          },
          error: function (e, t) {
            return console.error(e, t), Promise.resolve();
          }
        }),
        responsesCache: i(),
        requestsCache: i({
          serializable: !1
        }),
        hostsCache: u({
          caches: [a({
            key: "".concat("4.17.0", "-").concat(e)
          }), i()]
        }),
        userAgent: j("4.17.0").add({
          segment: "Recommend",
          version: "4.17.0"
        }).add({
          segment: "Browser"
        }),
        authMode: c.WithinQueryParameters
      };
    return C(r(r(r({}, s), n), {}, {
      methods: {
        getFrequentlyBoughtTogether: k,
        getRecommendations: N,
        getRelatedProducts: x,
        getTrendingFacets: J,
        getTrendingItems: E
      }
    }));
  }
  return R.version = "4.17.0", R;
});
export default exports;